/*@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400);*/

@font-face {
    font-family: 'IBM';
    src: url('./assets/IBMPlexMono-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: 'AppleSymbols';
    src: url('./assets/AppleSymbols.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}


@font-face {
    font-family: 'IBM';
    src: url('./assets/IBMPlexMono-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: bold;
}

@font-face {
    font-family: 'ApercuMono';
    src: url('./assets/ApercuMono.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(./assets/Roboto-Regular.ttf) format('truetype');
    font-weight: 400;
    
}
/*
@font-face {
    font-family: 'IBM';
    src: url('IBMPlexMono-Regular.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
}*/

html {
    margin: 0;
}
body {
    margin: 0 !important;
    height: 100%;
    width: 100vw;
    background: #fffff;
    font-family: 'Google Sans', Roboto, sans-serif;
    font-size: 1.375rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.75rem;
}

.h1 {
}

.label {
    font: 'Roboto', sans-serif;
    pointer-events: none;
}

text {
    transition: letter-spacing 0.5s;
    /*letter-spacing: -1.3px;*/
}

.node {
    stroke: #000;
    stroke-width: 1.5px;
}

.link {
    stroke: #999;
    stroke-width: 1.5px;
}

.hidden {
    display: none !important;
}

.orb {
    cursor: pointer;
}

 /*D3 SVG FORMATTING*/

#svg_div_div {
    height: 100% !important;
    width: 100vw;
    min-height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
}

#svg_div {
    height: calc(80vh - 80px) !important;
    margin-bottom: 80px;
    width: 90vw;
    max-width: 120vh;
   /* z-index: 1000;*/
}

#svg_id {
    transition: transform 0.5s;
}

#bellyman {
    opacity: 0.4;
}

.cityPoints {
    cursor: pointer;
}

/*.clicked,
.clicked path {
    stroke: red !important;
}*/

/*.primary {
    stroke: red;
}*/

.secondary path {
    fill: #ababab !important;
}

.pointerBox rectangle {
    stroke: purple !important;
}

/*TITLE*/

#title_div {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    cursor: pointer;
}

#title_img img {
    width: 400px;
    max-width: 75vw;
    height: auto;
    animation: blinker 2s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/*VIDEO*/
#video {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    max-width: 100vw;
    height: auto;
    /*z-index: 1050;*/
    display: none;
}

#video video {
    width: 100%;
}

/*RESET SITE CONTROLS*/

#reset_screen {
    cursor: pointer;
    background: url('./assets/rewind.svg');
    width: 16px;
    height: 16px;
}

/*about and links and card*/

#films {
    /*padding:74.48% 0 0 0;*/
    position:fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2050;
    min-height: 200px;
    min-width: 200px;
    background: white;
    align-items: center;
    justify-content: center;
    display: flex;
}

#films iframe {
    max-width: 100vw;
}

#card {
    position: fixed;
    right: 10vw;
    transform:  rotate(20deg);
    width: 30vh;
}

#links  {
    position: fixed !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    max-width: 76% !important;
    background: white;
    padding: 30px;
    font-size: 16px;
    line-height: 5px;
    align-items: center;
    justify-content: center;
}


#about  {
    position: fixed !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    max-width: 76% !important;
    background: white;
    padding: 30px;
    border-radius: 10px;
    border: 1px hsl(102.14deg 76.36% 80.14%) solid;
    font-size: max(14px, 2vw);
    line-height: calc(1.2 * max(14px, 2vw) );
    max-height: 100%;
    overflow-y: auto;
    z-index: 2100 !important;
}

#about::-webkit-scrollbar {
    width: 7px;
}
#about::-webkit-scrollbar-track {
    border-radius: 10px;
}
#about::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 10px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}



@media screen and (min-width: 1000px) {
    #about {
        font-size: 16px;
        line-height: 25px;
    }
}

/*SWIPER*/

.swiper {
    position: absolute !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 80vw;
    max-width: 80vw;
    max-height: 75vh;
    transition: opacity 0.4s;
    opacity: 0;
    z-index: 4000 !important;
}

.swiper-wrapper {
    align-items: center;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    padding-bottom: 30px !important;
/*    height: 65vh;
    max-height: 65vh;*/
}

.swiper-slide img {
    
    /*max-height: calc(58vh - 30px);*/
    max-height: 75vh;
    max-width: 80vw;
    

    transition: opacity 0.5s;
    /*opacity: 0;*/
/*    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;*/

    /*box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;*/
                /*rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,*/
                /*rgba(200, 200, 255, 0.25) 0px -50px 100px -20px, rgba(255, 255, 255, 0.3) 0px -30px 60px -30px,*/
                /*rgba(255, 255, 255, 0.9) 0px -25px 20px -20px,*/
                /*rgba(, 0.6) 0px 19px 38px, rgba(, 0.44) 0px -15px 12px;*/

    /*object-fit: scale-down;*/
}

.swiper-pagination-bullets {
    bottom: auto !important;
    top: 15px !important;
}
.swiper-pagination-bullet {
  /*width: 50px !important;*/
  /*height: 50px !important;*/
  text-align: center;
  /*line-height: 50px;*/
  /*font-size: 30px;*/
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}

.bigBullet {
    width: 50px !important;
    height: 50px !important;
    line-height: 50px;
    font-size: 30px;
}

.smallBullet {
    width: 20px !important;
    height: 20px !important;
    line-height: 20px;
    font-size: 12px;
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: #007aff;
}

.locationDiv {
    border-radius: 15px;
    background-color: white;
    width: 275px;
    max-width: 60vw;
    max-height: 75vh;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.locationBody {
    width: 90%;
    text-align: left;
    padding: 5px 0px 5px 10px;
}

.locationName {
    color: rgb(32, 33, 36);
    margin: 0px 0 10px 0;
}

.locationAddress,
.locationTime,
.locationDetails {
    color: rgb(112, 117, 122);
    font-size: 14px;
    line-height: 18px;
    padding: 5px 0 15px 25px;
}

.locationAddress {
    background: url('./assets/place.png');
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: 0px calc( 50% - 8px);
}

.locationTime {
    background: url('./assets/schedule.png');
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: 0px calc( 50% - 8px);
}

.locationDetails {
    background: url('./assets/details.png');
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: 0px calc( 50% - 8px);
}



.header {

    width: 100%;
    overflow: hidden;
    border-radius: 15px 15px 0 0;

}

.headerImg {
    /*height: 100%;*/
    width: 100%;
    /*transform: translateY(-30%) ;*/
}